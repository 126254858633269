/*********************
* COLORS (Default colors are commented out on the right of the variable)
**********************/
html, body, .app-frame {
  height: 100% !important;
  margin: 0 !important;
  font-family: "Open Sans", "Helvetica Neue", "Arial", "sans-serif";
  background-color: #fafafa;
  color: #444; }

body {
  height: auto !important;
  min-height: 100%; }

.btn--wide {
  min-width: 40px; }

a {
  color: #337ab7; }

a.disabled {
  pointer-events: none;
  cursor: default;
  color: #888; }

.modal {
  z-index: 10000 !important; }

.modal-dialog {
  margin-top: 72px !important;
  margin-bottom: 72px !important; }

.navbar-default {
  background: #fff; }

.app-wrapper {
  min-height: 100%;
  margin: 0 auto -70px; }

.push {
  height: 44px; }

.footer {
  position: static;
  height: 35px;
  padding-top: 8px;
  z-index: -100000; }
  .footer .brand {
    position: absolute;
    left: 12px; }

.top-navigation .navbar-brand {
  background: #333; }

.top-navigation .nav > li.active {
  background: transparent; }

.top-navigation .nav > li.active > a {
  color: #333; }

.top-navigation .nav > li > a:hover {
  color: #265185; }

.top-navigation .nav > li.active > a {
  color: #265185; }

.top-navigation .nav > li a:hover, .top-navigation .nav > li a:focus {
  color: #265185; }

.top-navigation .nav-tabs li.active a {
  background: #fff; }
  .top-navigation .nav-tabs li.active a:hover {
    background: #fff; }

a.navbar-brand.logo {
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center; }
  a.navbar-brand.logo div {
    display: flex;
    align-items: baseline; }
  a.navbar-brand.logo img {
    margin-right: 10px;
    max-width: 120px; }

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  background: #1ab394;
  color: #fff; }

.navbar-version {
  color: #e7eaec; }

.bold {
  font-weight: bold; }

.overflow-hidden {
  overflow: hidden; }

.text-charcoal {
  color: #444; }

.text-smaller {
  font-size: smaller; }

.text-larger {
  font-size: larger; }

.text-thin {
  font-weight: 100; }

.text-muted {
  color: #888; }

.text-uppercase {
  text-transform: uppercase; }

.inlineblock {
  display: inline-block; }

.mt-3 {
  margin-top: 3px; }

.mt-5 {
  margin-top: 5px; }

.mt-10 {
  margin-top: 10px; }

.mt-15 {
  margin-top: 15px; }

.mb-3 {
  margin-bottom: 3px; }

.mb-5 {
  margin-bottom: 5px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-15 {
  margin-bottom: 15px; }

.mr-2 {
  margin-right: 2px; }

.mr-5 {
  margin-right: 5px; }

.mr-10 {
  margin-right: 10px; }

.mr-15 {
  margin-right: 15px; }

.ml-auto {
  margin-left: auto; }

.ml-5 {
  margin-left: 5px; }

.ml-10 {
  margin-left: 10px; }

.ml-15 {
  margin-left: 15px; }

.w-100 {
  width: 100%; }

.pr-5 {
  padding-right: 5px; }

h5 {
  font-weight: bold; }

.nomargins {
  margin: 0 !important; }

.nomargins-side {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.nomargins-bottom {
  margin-bottom: 0 !important; }

.nopaddings {
  padding: 0 !important; }

.nopaddingleft {
  padding-left: 0 !important; }

.nopaddingright {
  padding-right: 0 !important; }

.nopaddingbottom {
  padding-bottom: 0 !important; }

.nopaddingleftright {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.full-height {
  height: 100%; }

.page-heading.even-paddings {
  padding-bottom: 10px; }

.pointable {
  cursor: pointer; }

.nobordertop {
  border-top: none; }

.table-fixed {
  table-layout: fixed; }

.break-on-wrap {
  word-wrap: break-word; }

.ibox.border-left {
  border-left: 2px solid #e7eaec; }

.ibox.border-right {
  border-right: 2px solid #e7eaec; }

.ibox.border-bottom {
  border-bottom: 2px solid #e7eaec !important; }

.ibox .ibox-title {
  padding: 7px !important;
  min-height: 48px;
  display: flex;
  align-items: center; }
  .ibox .ibox-title h5 {
    color: #777;
    font-size: 16px; }

.ibox .ibox-content {
  background: #fff; }

.ibox .ibox-content.paddings-thin {
  padding: 10px 15px; }

.ibox .header-margin {
  margin: 20px 0 20px 0px; }

.ibox .ibox-sub-title {
  border-top: 1px solid #e7eaec;
  padding: 6px 13px; }

.white-bg {
  background: #fff; }

.scroll-y {
  overflow-y: auto; }

/* Hide select dropdown arrow on IE, https://stackoverflow.com/questions/20163079/remove-select-arrow-on-ie*/
select::-ms-expand {
  display: none; }

/**
 * FLEX LAYOUT
 */
.flex-container {
  display: flex; }

.flex-container--center-align {
  align-items: center; }

.flex-container--direction-column {
  flex-direction: column; }

.flex-container--justify-center {
  justify-content: center; }

.flex-container--justify-space-between {
  justify-content: space-between; }

.flex-container--flex-flow-wrap {
  flex-flow: wrap; }

.flex-container--di-row-reverse {
  flex-direction: row-reverse; }

.flex-align-center {
  display: flex;
  align-items: center; }

.flex-align-baseline {
  display: flex;
  align-items: baseline; }

.flex-column {
  flex: 1; }

.flex-column--2 {
  flex: 2; }

.flex-column--3 {
  flex: 3; }

.flex-column--4 {
  flex: 4; }

.flex-column--5 {
  flex: 5; }

.text-center {
  text-align: center !important; }

.flex-container-toggle-btn {
  width: 5px;
  background: #0d8ddb;
  transition: width 0.3s ease-out;
  cursor: pointer;
  color: transparent;
  font-size: 18px; }

.flex-container-toggle-btn:hover {
  width: 14px;
  padding-left: 2px;
  background-color: #29a6f2;
  color: #074f7b; }

.half {
  width: 50%; }

.third {
  width: 33%; }

.two-third {
  width: 66%; }

.flex-align-center {
  align-items: center; }

.text-capitalize {
  text-transform: capitalize; }

.sub-nav {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px; }

button.sub-link {
  border-radius: 3px;
  background: transparent;
  font-size: 14px;
  border: none;
  padding: 3px 8px 4px;
  font-weight: bold; }
  button.sub-link:hover, button.sub-link.active {
    background-color: #1ab394;
    border-color: #1ab394;
    color: #fff; }
  button.sub-link + .sub-link {
    margin-left: 5px; }

/**
 * UI SELECT
 */
.ui-select-choices-group-label {
  background: aliceblue;
  border-bottom: 1px solid #EEE;
  font-size: 11px;
  font-weight: bold; }

.dropdown-menu .ui-select-choices-group .divider {
  margin: 0; }

.ui-select-no-choice {
  padding: 5px;
  box-shadow: 0 1px 0px rgba(86, 96, 117, 0.13);
  border-radius: none !important;
  border: 1px solid #EEE;
  color: #999;
  margin-top: -3px; }

.app-page-header {
  font-weight: bold; }

.annotations-viewer-holder {
  border-top: 2px solid #e7eaec;
  overflow: hidden; }

.position-relative {
  position: relative; }

/** Search Bar */
.main-search-bar {
  padding-left: 38px;
  font-size: 22px;
  padding-right: 38px; }

.search-icon {
  font-size: 22px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 4; }

.info-icon {
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 100px;
  z-index: 4; }

/** Search Bar Tooltip*/
.tooltipInfo .tooltip-inner {
  text-align: left;
  min-width: 700px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  padding: 0px 8px; }

.tooltipTable th, .tooltipTable td {
  text-align: left;
  border: 1px solid gray !important;
  font-size: 14px; }

/** Autocomplete */
.dropdown-menu {
  min-width: 100%; }

.ui-select-bootstrap > .ui-select-choices {
  min-width: 100%;
  width: auto; }

.button-link {
  background: none !important;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: #265185;
  margin: 0px 5px;
  text-decoration: underline; }

.search-options {
  margin-top: 10px;
  display: flex; }
  .search-options .search-option--save {
    margin-left: auto; }

faceted-search-filter {
  width: 100%; }

.filter-option {
  margin: 5px 0px; }

.filter-option-selection {
  word-wrap: break-word; }

/** Filter search bar */
#filter-search, #filter-search-within {
  border-radius: 60px;
  padding-left: 26px; }

.search-icon-sm {
  position: absolute;
  top: 9px;
  left: 10px;
  z-index: 4; }

/** Filter options title */
.filter-title:hover {
  cursor: pointer; }
  .filter-title:hover i, .filter-title:hover h4 {
    user-select: none; }

/** Search Results */
faceted-search-results {
  width: 100%; }
  faceted-search-results #results_header {
    display: flex; }
  faceted-search-results #results_text {
    width: 60%;
    margin: auto;
    margin-left: 0;
    word-wrap: break-word; }
  faceted-search-results #sort_dropdown select {
    display: inline-block;
    width: 200px; }

/** Highlight colour for searched query */
em {
  font-style: normal;
  color: #1ab394; }

/** Search Results Item */
.result-info {
  font-size: 12px; }

.result-link {
  line-height: 1.4;
  padding: 3px 0px; }

.result-body {
  font-size: 14px; }

/** Each Attachment file */
.attachment-file {
  font-size: 12px;
  margin: 0px 3px; }

/** Result sections i.e Tags, Attachments*/
.result-section-title {
  padding: 15px; }
  .result-section-title i, .result-section-title h5 {
    user-select: none; }

.result-section-content {
  padding: 0px 30px 15px 30px; }

/** Header for result item */
.mail-box-header-custom {
  background-color: #fff;
  border: 1px solid #e7eaec;
  border-bottom: 0;
  padding: 15px; }

/** Each tag for results */
.simple_tag-sm {
  background-color: #f3f3f4;
  border: 1px solid #e7eaec;
  border-radius: 2px;
  color: inherit;
  font-size: 10px;
  padding: 3px;
  display: inline-block;
  margin: 0px 2px; }

/** Category for tags */
.tag-category {
  text-transform: capitalize;
  width: 15%;
  text-align: right;
  padding-right: 10px;
  margin: 4px 0px;
  color: #000; }

.tag-new-input {
  display: inline;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ddd; }

.tag-row {
  padding: 2px 0px;
  position: relative; }
  .tag-row--edit {
    justify-content: flex-end; }
    .tag-row--edit a {
      margin-left: 8px; }
  .tag-row .fa-close {
    cursor: pointer;
    right: -20px;
    position: absolute; }
  .tag-row.text-center {
    justify-content: center; }

faceted-search-results-tags-list {
  width: 85%; }
  faceted-search-results-tags-list > div {
    display: flex;
    align-items: end;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    padding: 2px; }
    faceted-search-results-tags-list > div i {
      margin: 3px;
      font-size: 16px !important; }
      faceted-search-results-tags-list > div i:hover {
        color: #ed5565 !important; }

faceted-search-filter-option .filter-tags-list input.ui-select-search {
  display: block;
  align-items: end;
  border: 1px solid #e5e6e7 !important;
  border-radius: 1px;
  padding: 2px;
  width: 100% !important; }
  faceted-search-filter-option .filter-tags-list input.ui-select-search i {
    margin: 3px;
    font-size: 16px !important; }
    faceted-search-filter-option .filter-tags-list input.ui-select-search i:hover {
      color: #ed5565 !important; }

.ui-select-bootstrap .ui-select-choices-row.active > span {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #1ab394;
  width: 100%; }

.tags-list-select {
  border: none; }
  .tags-list-select .ui-select-match {
    display: flex;
    flex-flow: row wrap; }
    .tags-list-select .ui-select-match a {
      text-decoration: none;
      color: #333; }
  .tags-list-select .ui-select-match-item {
    border: 1px solid #ddd;
    padding: 5px 22px 5px 7px;
    margin: 2px;
    display: flex; }
    .tags-list-select .ui-select-match-item.btn-primary {
      background: #1ab394; }
  .tags-list-select .ui-select-match-close {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%); }
  .tags-list-select input.ui-select-search {
    flex-grow: 1;
    width: auto !important;
    height: auto !important; }
    .tags-list-select input.ui-select-search::-webkit-input-placeholder {
      opacity: 0.3; }
  .tags-list-select.ui-select-multiple div {
    display: flex;
    flex-wrap: wrap; }
  .tags-list-select.form-control[disabled] {
    background: none;
    border: none;
    cursor: auto; }
    .tags-list-select.form-control[disabled] input.ui-select-search {
      display: none; }
    .tags-list-select.form-control[disabled] .ui-select-match-item {
      padding: 5px 7px;
      margin: 2px;
      background: #eee;
      cursor: pointer; }
      .tags-list-select.form-control[disabled] .ui-select-match-item a {
        font-weight: bold; }

/** box to contain applied filters */
.applied-filters {
  background-color: #fff;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  display: block;
  padding: 6px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  min-height: 36px; }

.saved-search-list {
  font-size: 16px; }
  .saved-search-list tbody tr td {
    vertical-align: middle !important; }
    .saved-search-list tbody tr td input {
      width: 100%; }
    .saved-search-list tbody tr td button i {
      font-size: 16px; }

/** Triple Search **/
.triple-search select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: none;
  border: 1px solid #e5e6e7; }

.triple-search .input-group {
  display: flex; }
  .triple-search .input-group .input-group-prepend {
    display: flex;
    position: relative; }
    .triple-search .input-group .input-group-prepend select {
      border-radius: 5px 0px 0px 5px; }
  .triple-search .input-group .individual-dropdown {
    width: 40%; }
  .triple-search .input-group .relationship-dropdown {
    width: 100%; }
    .triple-search .input-group .relationship-dropdown select {
      border-radius: 5px 5px 5px 5px; }

.triple-search .table tbody tr td {
  vertical-align: middle; }

.triple-search i.fa.fa-caret-down {
  pointer-events: none;
  display: flex;
  position: absolute;
  z-index: 5;
  font-size: 16px;
  right: 0px;
  margin-right: 10px;
  top: 50%;
  transform: translateY(-50%); }

mark {
  background: #1ab394;
  color: #fff; }

.remove-search-within {
  font-size: 14px; }

.fa.pointable:hover {
  color: #1ab394; }

.icon-right {
  margin-left: auto; }

/** Used in text preview of search result item*/
.preview-text {
  white-space: pre-wrap; }

.no-pointer-events {
  pointer-events: none; }

@media (max-width: 768px) {
  body:not(.mini-navbar) {
    background-color: #fafafa; }
  .result-col {
    padding-left: 0px;
    margin-top: 10px; } }

#tagcloud {
  position: relative; }

#tagcloud_holder {
  position: inherit;
  width: inherit;
  display: inherit; }

#tagcloud svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

#tagcloud canvas {
  display: block;
  width: 100%;
  visibility: hidden; }

/*********************
* COLORS (Default colors are commented out on the right of the variable)
**********************/
.fs-results.tabs-container .fs-results__list {
  background: #fff; }

/*********************
* COLORS (Default colors are commented out on the right of the variable)
**********************/
html {
  font-size: 16px; }

search-bar-widget {
  font-size: 1rem !important; }
